"use client";

import ValidatePath from "../lib/pathHelpers";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useState, useContext } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Container, Row, Col } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import Authenticate from "../components/Authenticate";
import { FaHome } from "react-icons/fa";
import { FaCircleUser } from "react-icons/fa6";
import { SessionContext } from "../app/hooks/useSession";
import { useRouter } from "next/navigation";
import Image from "next/image";

export default function AppNavigation({ path }: { path?: string }) {
  const {
    user,
    isLoading: isLoadingSession,
    signOut,
  } = useContext(SessionContext);
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  // if path(-override) is provided, skip ValidatePath() which requires <Suspense/>
  const {
    pathname,
    isPathGames,
    isPathGame,
    isPathReport,
    isPathTeamSettings,
  } = path
    ? {
        pathname: path,
        isPathGames: false,
        isPathGame: false,
        isPathReport: false,
        isPathTeamSettings: false,
      }
    : ValidatePath();
  const router = useRouter();

  const onSignOut = () => {
    signOut();
    router.push(window.location.pathname);
    router.refresh();
  };

  // team name available: sessionTeams?.[pathname.match(/\/t\/(\d+)\/?.*$/)?.[1]]?.name
  return (
    <>
      <Navbar className="py-0" style={{ height: 55 }}>
        <Container fluid className="px-0">
          <Row className="w-100 mx-0">
            <Col xs={6} className="px-0 d-flex align-items-center">
              <Breadcrumb className="m-0">
                <Breadcrumb.Item
                  onClick={() => router.push("/")}
                  className="d-flex align-items-center">
                  <FaHome className="my-2 text-secondary" />
                </Breadcrumb.Item>
                {isLoadingSession === "Session Ready" ||
                isLoadingSession == "Session Teams Ready" ? (
                  <>
                    {isPathGames ||
                    isPathGame ||
                    isPathReport ||
                    isPathTeamSettings ? (
                      <Breadcrumb.Item
                        active={isPathGames}
                        onClick={() =>
                          router.push(pathname.match(/\/t\/\d+/g)?.[0])
                        }
                        className="d-flex align-items-center text-truncate">
                        Team
                      </Breadcrumb.Item>
                    ) : null}
                    {isPathGame ? (
                      <Breadcrumb.Item
                        active={isPathGame}
                        className="d-flex align-items-center">
                        Game
                      </Breadcrumb.Item>
                    ) : null}
                    {isPathReport || isPathTeamSettings ? (
                      <Breadcrumb.Item
                        active={isPathReport || isPathTeamSettings}
                        className="d-flex align-items-center">
                        {isPathReport ? "Report" : "Manage"}
                      </Breadcrumb.Item>
                    ) : null}
                  </>
                ) : null}
              </Breadcrumb>
            </Col>
            {isLoadingSession === "Session Ready" ||
            isLoadingSession == "Session Teams Ready" ? (
              <Col xs={6} className="d-flex justify-content-end px-0">
                {user !== null ? (
                  <Nav>
                    <NavDropdown
                      className="d-flex align-items-center justify-content-end"
                      align="end"
                      title={
                        user?.user_metadata?.hasOwnProperty("avatar_url") ? (
                          <>
                            <span className="text-secondary">
                              {user?.email?.match(/^([^@]*)@/)[1]}
                            </span>
                            &nbsp;
                            <Image
                              src={user?.user_metadata?.avatar_url}
                              referrerPolicy="no-referrer"
                              alt="User"
                              width={30}
                              height={30}
                              className="shadow-sm border"
                              style={{
                                verticalAlign: "middle",
                                borderRadius: "50%",
                              }}
                            />
                          </>
                        ) : (
                          <FaCircleUser className="text-secondary" />
                        )
                      }>
                      {user !== null ? (
                        <NavDropdown.Item onClick={() => onSignOut()}>
                          Sign Out
                        </NavDropdown.Item>
                      ) : null}
                    </NavDropdown>
                  </Nav>
                ) : (
                  <FaCircleUser
                    className="fs-5 text-secondary"
                    onClick={() => setShowAuthModal(true)}
                  />
                )}
              </Col>
            ) : null}
          </Row>
        </Container>
      </Navbar>
      <Authenticate
        show={showAuthModal}
        onClose={() => setShowAuthModal(false)}
      />
    </>
  );
}
